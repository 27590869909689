
  import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
  import { RULES } from '@/components/forms'

@Component({
  name: 'GColorPicker',
})
  export default class GColorPicker extends Vue {
  @Prop({ type: String, default: '#1976D2' }) readonly value!: string;
  @Prop({ type: String, default: '' }) readonly label!: string;
  // Title prop removed as requested
  @Prop({ type: Number, default: 6 }) readonly maxRecentColors!: number;
  @Prop({ type: Boolean, default: true }) required!: boolean

  color: string = '#000000';
  mode: string = 'hexa';
  isValidHex: boolean = true;
  recentColors: string[] = [];
  fieldRequired = RULES.isRequired

  // Dialog and advanced control properties
  dialog: boolean = false;
  hue: number = 0;
  saturation: number = 100;
  lightness: number = 50;

  swatches: string[][] = [
    ['#FF0000', '#FF9900', '#FFFF00', '#00FF00', '#00FFFF', '#0000FF'],
    ['#FF1493', '#FFA500', '#FFFF99', '#98FB98', '#87CEFA', '#7B68EE'],
    ['#800000', '#FFA500', '#808000', '#008000', '#008080', '#000080'],
    ['#A52A2A', '#C0C0C0', '#808080', '#4B0082', '#ee82ee', '#FFD700'],
    ['#000000', '#333333', '#666666', '#999999', '#CCCCCC', '#FFFFFF'],
  ];

  @Watch('value')
  onValueChanged (newValue: string): void {
    this.color = newValue
  }

  mounted (): void {
    this.loadRecentColors()
  }

  @Emit('input')
  updateColor (color: any): string {
    this.isValidHex = true
    let result = ''
    if (typeof color === 'object' && color.hex) {
      // Si el objeto contiene la propiedad hex, se utiliza esa (ya es formato HEX sin alpha)
      result = color.hex
    } else if (typeof color === 'string') {
      result = color
      // Si el string tiene 9 caracteres (por ejemplo, "#FF0000FF"), se remueven los dos últimos para obtener el HEX
      if (result.length === 9) {
        result = result.slice(0, 7)
      }
    }
    return result
  }

  // Handles input from v-color-picker
  onColorPickerInput (color: string): void {
    this.color = color
    this.updateColor(color)
    this.updateSlidersFromColor()
  }

  validateHex (): void {
    const hexRegex = /#([a-fA-F0-9]{6}|[a-fA-F0-9]{8})/
    this.isValidHex = hexRegex.test(this.color)

    if (this.isValidHex) {
      this.$emit('input', this.color)
    }
  }

  @Emit('save')
  saveColor (): string | undefined {
    if (!this.isValidHex) return undefined

    if (!this.recentColors.includes(this.color)) {
      this.recentColors.unshift(this.color)
      if (this.recentColors.length > this.maxRecentColors) {
        this.recentColors.pop()
      }
      localStorage.setItem('recentColors', JSON.stringify(this.recentColors))
    }
    return this.color
  }

  selectRecentColor (color: string): void {
    this.color = color
    this.updateColor(color)
  }

  loadRecentColors (): void {
    const saved = localStorage.getItem('recentColors')
    if (saved) {
      try {
        this.recentColors = JSON.parse(saved)
      } catch (e) {
        console.error('Error al cargar colores recientes:', e)
        this.recentColors = []
      }
    }
  }

  // Dialog controls
  openDialog (): void {
    this.updateSlidersFromColor()
    this.dialog = true
  }

  closeDialog (): void {
    this.dialog = false
  }

  confirmColor (): void {
    this.saveColor()
    this.closeDialog()
  }

  // Updates slider values based on the current HEX color (converts HEX to HSL)
  updateSlidersFromColor (): void {
    const hsl = this.hexToHSL(this.color)
    if (hsl) {
      this.hue = hsl.h
      this.saturation = hsl.s
      this.lightness = hsl.l
    }
  }

  // Updates the HEX color based on slider values (converts HSL to HEX)
  updateColorFromSliders (): void {
    const hex = this.hslToHex(this.hue, this.saturation, this.lightness)
    this.color = hex
    this.updateColor(hex)
  }

  // Helper: Converts HEX to HSL
  hexToHSL (hex: string): { h: number; s: number; l: number } | null {
    if (typeof hex !== 'string') {
      hex = String(hex)
    }
    hex = hex.replace(/^#/, '')
    if (hex.length !== 6) return null
    const r = parseInt(hex.substring(0, 2), 16) / 255
    const g = parseInt(hex.substring(2, 4), 16) / 255
    const b = parseInt(hex.substring(4, 6), 16) / 255

    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    let h = 0
    let s = 0
    const l = (max + min) / 2

    if (max !== min) {
      const d = max - min
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0)
          break
        case g:
          h = (b - r) / d + 2
          break
        case b:
          h = (r - g) / d + 4
          break
      }
      h = h * 60
    }
    return { h: Math.round(h), s: Math.round(s * 100), l: Math.round(l * 100) }
  }

  // Helper: Converts HSL to HEX
  hslToHex (h: number, s: number, l: number): string {
    s /= 100
    l /= 100
    const c = (1 - Math.abs(2 * l - 1)) * s
    const x = c * (1 - Math.abs((h / 60) % 2 - 1))
    const m = l - c / 2

    const [r, g, b] = this.getRGBFromHSL(h, c, x)

    return this.rgbToHex(r, g, b, m)
  }

  getRGBFromHSL (h: number, c: number, x: number): [number, number, number] {
    if (h < 60) return [c, x, 0]
    if (h < 120) return [x, c, 0]
    if (h < 180) return [0, c, x]
    if (h < 240) return [0, x, c]
    if (h < 300) return [x, 0, c]
    return [c, 0, x]
  }

  rgbToHex (r: number, g: number, b: number, m: number): string {
    const toHex = (n: number) => {
      const hex = Math.round((n + m) * 255).toString(16)
      return hex.length === 1 ? '0' + hex : hex
    }
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`
  }

  @Watch('value', { immediate: true })
  onValueChange (newValue: string): void {
    this.color = newValue
  }
  }
