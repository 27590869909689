
  import { Component, Watch } from 'vue-property-decorator'
  import FormTitle from '@/components/forms/FormTitle.vue'
  import PTitle from '@/components/config/PTitle.vue'
  import PSubtitle from '@/components/config/PSubtitle.vue'
  import AlignmentIcon from '@/components/config/AlignmentIcon.vue'
  import GTextarea from '@/components/core/input/GTextarea.vue'
  import { HomeConfigClass } from '@/entities/system/ConfigWebPage'
  import { WebPageView } from '@/components/config/webPage/WebPageView'
  import SeoPreview from '@/components/config/SeoPreview.vue'
  import LinkPreview from '@/components/config/LinkPreview.vue'

@Component({
  components: { LinkPreview, SeoPreview, GTextarea, AlignmentIcon, PSubtitle, PTitle, FormTitle },
})
  export default class WebHome extends WebPageView {
  formData = {
    seo: {
      title: '',
      subtitle: '',
      alignment: '',
    },
    stock: {
      title: '',
      subtitle: '',
      alignment: '',
    },
    clients: {
      title: '',
      subtitle: '',
      alignment: '',
    },
    scheduled: {
      title: '',
      subtitle: '',
      alignment: '',
    },
    location: {
      title: '',
      subtitle: '',
      alignment: '',
    },
    team: {
      title: '',
      subtitle: '',
      alignment: '',
    },
  }

  fields = {
    seo: {
      title: 'Ejemplo: Home - Automotriz',
      description: 'Ejemplo: Encuentra los mejores autos en Automotriz',
    },
    stock: {
      title: 'Ejemplo: “Nuestros autos”, “Mira nuestro stock”,  “Stock”, “Nuestro stock”, etc...',
      subtitle: 'Ejemplo: “Los mejores autos del país”, “Revisa nuestros vehículos”,  “Cotiza y compara”, etc...',
    },
    clients: {
      title: 'Eje: Nuestros clientes”, “Clientes felices”,  “Clientes”, etc...',
      subtitle: 'Eje: “Conoce a quienes ya confiaron en nosotros”, “Cientos de personas ya hicieron negocios con nosotros”, etc...',
    },
    schedule: {
      title: 'Ejemplo: “Nuestro horario de atención”, “Horario”,  Horario de atención”, etc...',
      subtitle: 'Ejemplo: “Visítanos cuando quieras”, “Te esperamos”,  “Ven a conocernos”, etc...',
    },
    location: {
      title: 'Ejemplo: “Nuestra ubicación”, “Ubicación”, “Dónde nos encuentras”, etc...',
      subtitle: 'Ejemplo: “Visítanos cuando quieras”, “Te esperamos”,  “Ven a conocernos”, etc...',
    },
    team: {
      title: 'Ejemplo: “Nuestra equipo”, “Colaboradores”, “Nuestros ejecutivos”, etc...',
      subtitle: 'Ejemplo: “Siempre listos para atenderte”, “Escríbenos”, “Te esperamos con una sonrisa”, etc...',
    },
  }

  async save () {
    if (!this.$refs.form.validate()) {
      await this.setFieldError()
      return
    }

    const system = await this.fetchData({
      query: { name: 'fetchSpecial', model: 'Person', params: { id: this.systemId } },
      force: true,
    })

    await this.pushData({
      model: 'Person',
      fields: {
        id: this.systemId,
        config_web_page: {
          ...system.configWebPage,
          home: this.formData,
        },
      },
    })

    this.$emit('update')
  }

  @Watch('data.home', { immediate: true, deep: true })
  async onGetData (val: HomeConfigClass) {
    if (!val) return

    this.formData.seo.title = val.seo.title
    this.formData.seo.subtitle = val.seo.subtitle
    this.formData.stock.title = val.stock.title
    this.formData.stock.subtitle = val.stock.subtitle
    this.formData.clients.title = val.clients.title
    this.formData.clients.subtitle = val.clients.subtitle
    this.formData.scheduled.title = val.scheduled.title
    this.formData.scheduled.subtitle = val.scheduled.subtitle
    this.formData.scheduled.alignment = val.scheduled.alignment
    this.formData.location.title = val.location.title
    this.formData.location.subtitle = val.location.subtitle
    this.formData.location.alignment = val.location.alignment
    this.formData.team.title = val.team.title
    this.formData.team.subtitle = val.team.subtitle
    this.formData.team.alignment = val.team.alignment
  }
  }
