
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import PSubtitle from '@/components/config/PSubtitle.vue'

interface Font {
  family: string;
  variants: string[];
}

@Component({
  components: { PSubtitle },
})
  export default class FontList extends Vue {
  @Prop({ type: Object, default: () => ({ family: '', weight: '' }) }) value!: { family: string; weight: string };

  fonts: Font[] = [];
  selectedFont: Font | null = null;
  selectedWeight: string = 'regular';

  mounted () {
    this.fetchFonts()
  }

  async fetchFonts () {
    const apiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY // Usa tu clave de API configurada en el entorno
    const response = await fetch(`https://www.googleapis.com/webfonts/v1/webfonts?key=${apiKey}`)
    const data = await response.json()
    this.fonts = data.items.map((item: any) => ({
      family: item.family,
      variants: item.variants.filter((variant: string) => variant === 'regular' || /^[1-9]00$/.test(variant)),
    }))
    this.initializeSelection()
  }

  initializeSelection () {
    if (this.value.family) {
      const matchingFont = this.fonts.find(font => font.family === this.value.family)
      if (matchingFont) {
        this.selectedFont = matchingFont
        this.selectedWeight = this.value.weight === '400' ? 'regular' : this.value.weight
      }
    }
  }

  @Watch('value', { immediate: true, deep: true })
  onValueChange (newValue: { family: string; weight: string }) {
    if (newValue.family !== this.selectedFont?.family || newValue.weight !== this.selectedWeight) {
      this.initializeSelection()
    }
  }

  onFontChange (font: Font) {
    if (font) {
      this.selectedFont = font
      this.selectedWeight = 'regular'
      this.applyFont()
    }
  }

  get weightOptions () {
    if (!this.selectedFont) return []
    return this.selectedFont.variants.map(variant => {
      let label = variant
      if (variant === 'regular') {
        label = 'Regular'
      } else {
        const weightNum = parseInt(variant)
        switch (weightNum) {
          case 100:
            label = 'Thin'
            break
          case 200:
            label = 'Extra Light'
            break
          case 300:
            label = 'Light'
            break
          case 500:
            label = 'Medium'
            break
          case 600:
            label = 'Semi Bold'
            break
          case 700:
            label = 'Bold'
            break
          case 800:
            label = 'Extra Bold'
            break
          case 900:
            label = 'Black'
            break
          default:
            label = variant
        }
      }
      return { value: variant, label }
    })
  }

  applyFont () {
    if (this.selectedFont) {
      const weight = this.selectedWeight || 'regular'
      const fontWeight = weight === 'regular' ? '400' : weight
      const link = document.createElement('link')
      link.href = `https://fonts.googleapis.com/css2?family=${this.selectedFont.family.replace(/ /g, '+')}:wght@${fontWeight}&display=swap`
      link.rel = 'stylesheet'
      document.head.appendChild(link)
      this.$emit('input', { family: this.selectedFont.family, weight: fontWeight })
    }
  }
  }
