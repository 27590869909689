
  import { Component, Watch } from 'vue-property-decorator'
  import FormTitle from '@/components/forms/FormTitle.vue'
  import PTitle from '@/components/config/PTitle.vue'
  import PSubtitle from '@/components/config/PSubtitle.vue'
  import AlignmentIcon from '@/components/config/AlignmentIcon.vue'
  import GTextarea from '@/components/core/input/GTextarea.vue'
  import { SectionConfigClass } from '@/entities/system/ConfigWebPage'
  import GCostField from '@/components/core/input/GCostField.vue'
  import { WebPageView } from '@/components/config/webPage/WebPageView'
  import LinkPreview from '@/components/config/LinkPreview.vue'
  import SeoPreview from '@/components/config/SeoPreview.vue'
  import GColorPicker from '../GColorPicker.vue'

@Component({
  components: { SeoPreview, LinkPreview, GCostField, GTextarea, AlignmentIcon, PSubtitle, PTitle, FormTitle, GColorPicker },
})
  export default class WebHome extends WebPageView {
  formData = {
    seo: {
      title: '',
      subtitle: '',
      alignment: '',
    },
    display: {
      soldAfterDays: 5,
      sold: false,
      enablement: false,
      enablementWithOutPrice: false,
      enablementWithOutPhoto: true,
      availableWithOutPhoto: true,
      availableWithOutPrice: true,
    },
    extraButton: {
      display: false,
      title: '',
      link: '',
      background: '',
      text: '',
    },
  }

  fields = {
    seo: {
      title: 'Ejemplo: Stock - Automotriz',
      description: 'Ejemplo: Nuestro mejor stock',
    },
  }

  async save () {
    if (!this.$refs.form.validate()) {
      await this.setFieldError()
      return
    }

    const system = await this.fetchData({
      query: { name: 'fetchSpecial', model: 'Person', params: { id: this.systemId } },
      force: true,
    })

    await this.pushData({
      model: 'Person',
      fields: {
        id: this.systemId,
        config_web_page: {
          ...system.configWebPage,
          stock: this.formData,
        },
      },
    })

    this.$emit('update')
  }

  @Watch('data.stock', { immediate: true, deep: true })
  async onGetData (val: SectionConfigClass) {
    if (!val) return

    this.formData.seo.title = val.seo.title
    this.formData.seo.subtitle = val.seo.subtitle
    if (val?.display) {
      this.formData.display = val?.display
    }

    if (val?.extraButton) {
      this.formData.extraButton = val?.extraButton
    }
  }

  @Watch('formData.display', { immediate: true, deep: true })
  async onEnablement (val: any) {
    if (!val.enablement) {
      this.formData.display.enablementWithOutPhoto = false
      this.formData.display.enablementWithOutPrice = false
    }
  }
  }
