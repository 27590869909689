
  import { Component, Watch } from 'vue-property-decorator'
  import GFiles from '@/components/core/files/GFiles.vue'
  import PTitle from '@/components/config/PTitle.vue'
  import PSubtitle from '@/components/config/PSubtitle.vue'
  import { StyleConfigClass } from '@/entities/system/ConfigWebPage'
  import GColorPicker from '@/components/config/GColorPicker.vue'
  import FontList from '@/components/config/FontList.vue'
  import { WebPageView } from '@/components/config/webPage/WebPageView'

@Component({
  components: { FontList, GColorPicker, PSubtitle, PTitle, GFiles },
})
  export default class WebIdentity extends WebPageView {
  buttonsStyle = [
    { value: '', description: 'Estandar' },
    { value: 'outlined', description: 'Con borde' },
    { value: 'plain', description: 'Minimalista' },
    { value: 'tonal', description: 'Plano sin sombra' },
  ]

  // Agrega las opciones para Rounded y Elevation
  roundedOptions = [
    { value: '0', description: 'No redondeado' },
    { value: 'sm', description: 'Pequeño' },
    { value: 'md', description: 'Por defecto' },
    { value: 'lg', description: 'Grande' },
    { value: 'xl', description: 'Extra grande' },
  ]

  elevationOptions = [
    { value: 0, description: '0' },
    { value: 1, description: '1' },
    { value: 2, description: '2' },
    { value: 3, description: '3' },
    { value: 4, description: '4' },
    { value: 5, description: '5' },
  ]

  formData = {
    buttonStyle: { value: '', description: 'Por defecto', rounded: '0', elevation: 4 },
    cardStyle: { rounded: '0', elevation: 0 },
    colors: {
      primary: '',
      secondary: '',
      info: '',
      error: '',
      success: '',
      warning: '',
      background: '',
      text: '',
      header: {
        background: '',
        text: '',
      },
      footer: {
        background: '',
        text: '',
      },
      card: {
        background: '',
        text: '',
      },
      confirm: {
        background: '',
        text: '',
        icon: '',
      },
    },
    font: {
      family: '',
      weight: '',
    },
  }

  // Methods
  async save () {
    if (!this.$refs.form.validate()) {
      await this.setFieldError()
      return
    }

    const system = await this.fetchData({
      query: { name: 'fetchSpecial', model: 'Person', params: { id: this.systemId } },
      force: true,
    })

    const style = {
      ...this.formData,
    }

    await this.pushData({
      model: 'Person',
      fields: {
        id: system.id,
        config_web_page: {
          ...system.configWebPage,
          style,
        },
      },
    })

    this.$emit('update')
  }

  // Getters

  // Watchers
  @Watch('data.style', { immediate: true, deep: true })
  async onGetData (val: StyleConfigClass) {
    if (!val) return

    this.formData.colors = val.colors
    this.formData.buttonStyle = val.buttonStyle
    this.formData.font = val.font
    this.formData.cardStyle = val.cardStyle
  }
  }
