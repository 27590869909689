
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import PSubtitle from '@/components/config/PSubtitle.vue'

@Component({
  components: { PSubtitle },
})
  export default class LinkPreview extends Vue {
  @Prop({ type: String, default: '' }) logo!: string;
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: String, default: '' }) description!: string;
  @Prop({ type: String, default: 'www.automotora.com' }) url!: string;
  }
