
  import { Component, Watch } from 'vue-property-decorator'
  import GTextarea from '@/components/core/input/GTextarea.vue'
  import PTitle from '@/components/config/PTitle.vue'
  import { WebPageView } from '@/components/config/webPage/WebPageView'
  import SeoPreview from '@/components/config/SeoPreview.vue'
  import LinkPreview from '@/components/config/LinkPreview.vue'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'

interface History {
  date: string;
  icon: string;
  title: string;
  description: string;
}

interface Milestone {
  id: string;
  date: string;
  icon: string;
  title: string;
  description: string;
  showOnWebsite: boolean;
}

interface CultureAndValues {
  subtitle: string;
  description: string;
}

interface AboutUs {
  seo: {
    title: string;
    subtitle: string;
    alignment: string;
  };
  slogan: string;
  history: History;
  milestones: Milestone[];
  showHistory: boolean;
  companyTitle: string;
  backgroundImage: string;
  sectionSubtitle: string;
  cultureAndValues: CultureAndValues;
  showCultureAndValues: boolean;
}

@Component({
  components: { LinkPreview, SeoPreview, PTitle, GTextarea, GDatePicker },
})
  export default class WebAbout extends WebPageView {
  valid: boolean = true

  about: AboutUs = {
    seo: {
      title: '',
      subtitle: '',
      alignment: '',
    },
    slogan: null,
    history: {
      date: null,
      icon: null,
      title: null,
      description: null,
    },
    milestones: [],
    showHistory: true,
    companyTitle: null,
    backgroundImage: null,
    sectionSubtitle: null,
    cultureAndValues: {
      subtitle: null,
      description: null,
    },
    showCultureAndValues: true,
  }

  // Regla simple para campos requeridos.
  fieldRequired: ((value: string) => boolean | string)[] = [
    (value: string) => !!value || 'Campo requerido',
  ]

  // Opciones de iconos predefinidas.
  iconOptions = [
    { url: 'mdi-home', name: 'Home' },
    { url: 'mdi-car', name: 'Car' },
    { url: 'mdi-truck', name: 'Truck' },
    { url: 'mdi-briefcase', name: 'Briefcase' },
    { url: 'mdi-account', name: 'Account' },
    { url: 'mdi-account-circle', name: 'Account Circle' },
    { url: 'mdi-star', name: 'Star' },
    { url: 'mdi-map-marker', name: 'Map Marker' },
    { url: 'mdi-phone', name: 'Phone' },
    { url: 'mdi-email', name: 'Email' },
    { url: 'mdi-calendar', name: 'Calendar' },
    { url: 'mdi-cog', name: 'Settings' },
    { url: 'mdi-help-circle', name: 'Help' },
    { url: 'mdi-lock', name: 'Lock' },
    { url: 'mdi-check-circle', name: 'Check Circle' },
    { url: 'mdi-heart', name: 'Heart' },
    { url: 'mdi-thumb-up', name: 'Thumb Up' },
    { url: 'mdi-account-edit', name: 'Account Edit' },
    { url: 'mdi-information', name: 'Information' },
    { url: 'mdi-palette', name: 'Palette' },
  ]

  @Watch('data.aboutUs', { immediate: true, deep: true })
  onAboutChanged (val: any) {
    if (val) {
      this.about.seo.title = val.seo?.title || ''
      this.about.seo.subtitle = val.seo?.subtitle || ''
      this.about.slogan = val.slogan || ''
      this.about.history = {
        date: val.history?.date || '',
        icon: val.history?.icon || '',
        title: val.history?.title || '',
        description: val.history?.description || '',
      }

      this.about.showHistory = val.showHistory || false
      this.about.companyTitle = val.companyTitle || ''
      this.about.backgroundImage = val.backgroundImage || ''
      this.about.sectionSubtitle = val.sectionSubtitle || ''
      this.about.cultureAndValues = {
        subtitle: val.cultureAndValues?.subtitle || '',
        description: val.cultureAndValues?.description || '',
      }
      this.about.showCultureAndValues = val.showCultureAndValues || false
      if (val.milestones && Array.isArray(val.milestones)) {
        this.about.milestones = val.milestones.map((m: any, index: number) => ({
          id: `milestone${index + 1}`,
          date: m.date || '',
          icon: m.icon || '',
          title: m.title || '',
          description: m.description || '',
          showOnWebsite: m.showOnWebsite || false,
        }))
      }
    }
  }

  addMilestone () {
    const newId = 'milestone' + (this.about.milestones.length + 1)
    this.about.milestones.push({
      id: newId,
      date: '',
      icon: '',
      title: '',
      description: '',
      showOnWebsite: false,
    })
  }

  removeMilestone (index: number) {
    this.about.milestones.splice(index, 1)
  }

  async save () {
    if (!this.$refs.form.validate()) {
      return
    }

    const aboutUsPayload = {
      slogan: this.about.slogan,
      history: { ...this.about.history },
      milestones: this.about.milestones.map(m => ({ ...m })),
      showHistory: this.about.showHistory,
      companyTitle: this.about.companyTitle,
      backgroundImage: this.about.backgroundImage,
      sectionSubtitle: this.about.sectionSubtitle,
      cultureAndValues: { ...this.about.cultureAndValues },
      showCultureAndValues: this.about.showCultureAndValues,
      seo: this.about.seo,
    }

    const system = await this.fetchData({
      query: { name: 'fetchSpecial', model: 'Person', params: { id: this.systemId } },
      force: true,
    })

    await this.pushData({
      model: 'Person',
      fields: {
        id: this.systemId,
        config_web_page: {
          ...system.configWebPage,
          aboutUs: aboutUsPayload,
        },
      },
    })

    this.$emit('update')
  }
  }
