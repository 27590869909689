
  import { Component, Watch } from 'vue-property-decorator'
  import GFiles from '@/components/core/files/GFiles.vue'
  import PTitle from '@/components/config/PTitle.vue'
  import PSubtitle from '@/components/config/PSubtitle.vue'
  import GColorPicker from '@/components/config/GColorPicker.vue'
  import SeoPreview from '@/components/config/SeoPreview.vue'
  import { WebPageView } from '@/components/config/webPage/WebPageView'
  import { CorporateIdentityConfigClass } from '@/entities/system/ConfigWebPage'

@Component({
  components: { SeoPreview, GColorPicker, PSubtitle, PTitle, GFiles },
})
  export default class WebIdentity extends WebPageView {
  idProcess = null
  filesParameters = []

  formData = {
    facade: [],
    facadeHeight: 250,
    facadeHeightMobile: 250,
    logo: [],
    favicon: [],
  }

  fields = {
    logo: {
      properties: {
        accept: '',
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        icon: '',
      },
    },
    facade: {
      properties: {
        accept: '',
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        icon: '',
      },
    },
    favicon: {
      properties: {
        accept: '',
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        icon: '',
      },
    },
  }

  // Methods
  async mounted () {
    this.filesParameters = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'person' } } }] },
    })

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'person' } },
    })

    this.setProperties(this.filesParameters, 'logo', 'logo')
    this.setProperties(this.filesParameters, 'facade_photo', 'facade')
    this.setProperties(this.filesParameters, 'favicon', 'favicon')
    this.idProcess = process[0].id

    const files = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: this.systemId } },
          { parameter: { process: { id: { _eq: process[0].id } } } },
        ],
      },
      force: true,
    })

    this.formData.logo = files.filter(file => file.parameter.name === 'logo')
    this.formData.facade = files.filter(file => file.parameter.name === 'facade_photo')
    this.formData.favicon = files.filter(file => file.parameter.name === 'favicon')
  }

  setProperties (fileInfo, fileTypeName, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)

    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  openFavicon () {
    window.open('https://www.ionos.es/tools/crear-favicon#tool', '_blank')
  }

  async save () {
    if (!this.$refs.form.validate()) {
      await this.setFieldError()
      return
    }

    const system = await this.fetchData({
      query: { name: 'fetchSpecial', model: 'Person', params: { id: this.systemId } },
      force: true,
    })

    if (this.formData.logo?.length) {
      await this.handleFileType(this.formData.logo, { properties: this.fields.logo.properties }, this.idProcess, system.id)
    }
    if (this.formData.facade?.length) {
      await this.handleFileType(this.formData.facade, { properties: this.fields.facade.properties }, this.idProcess, system.id)
    }
    if (this.formData.favicon?.length) {
      await this.handleFileType(this.formData.favicon, { properties: this.fields.favicon.properties }, this.idProcess, system.id)
    }

    const corporateIdentity = {
      companyLogo: this.formData.logo[0]?.url || this.formData.logo?.[0]?.src,
      facadeImage: this.formData.facade[0]?.url || this.formData.facade?.[0]?.src,
      favicon: this.formData.favicon[0]?.url || this.formData.favicon?.[0]?.src,
      facadeHeight: this.formData.facadeHeight,
      facadeHeightMobile: this.formData.facadeHeightMobile,
    }

    await this.pushData({
      model: 'Person',
      fields: {
        id: system.id,
        config_web_page: {
          ...system.configWebPage,
          corporateIdentity,
        },
      },
    })

    this.$emit('update')
  }
  // Getters

  // Watchers
  @Watch('data.corporateIdentity', { immediate: true, deep: true })
  onAboutChanged (val: CorporateIdentityConfigClass) {
    if (val) {
      this.formData.facadeHeight = val?.facadeHeight
      this.formData.facadeHeightMobile = val?.facadeHeightMobile
    }
  }
  }
