
  import { Component, Watch } from 'vue-property-decorator'
  import WebHome from '@/components/config/webPage/WebHome.vue'
  import WebIdentity from '@/components/config/webPage/WebIdentity.vue'
  import WebPurchase from '@/components/config/webPage/WebPurchase.vue'
  import WebConsignment from '@/components/config/webPage/WebConsignment.vue'
  import WebRenewal from '@/components/config/webPage/WebRenewal.vue'
  import WebFinancing from '@/components/config/webPage/WebFinancing.vue'
  import WebContact from '@/components/config/webPage/WebContact.vue'
  import WebPrivacy from '@/components/config/webPage/WebPrivacy.vue'
  import WebAbout from '@/components/config/webPage/WebAbout.vue'
  import PTitle from '@/components/config/PTitle.vue'
  import PSubtitle from '@/components/config/PSubtitle.vue'
  import { GForm } from '@/components/forms/GForm'
  import { ConfigWebPageClass } from '@/entities/system/ConfigWebPage'
  import WebTerms from '@/components/config/webPage/WebTerms.vue'
  import WebStock from '@/components/config/webPage/WebStock.vue'
  import WebStyle from '@/components/config/webPage/WebStyle.vue'

  const { VUE_APP_WEB_URL } = process.env

@Component({
  components: {
    WebStyle,
    WebStock,
    WebTerms,
    PSubtitle,
    PTitle,
    WebAbout,
    WebPrivacy,
    WebContact,
    WebFinancing,
    WebRenewal,
    WebConsignment,
    WebPurchase,
    WebIdentity,
    WebHome,
  },
})
  export default class SystemWebPage extends GForm {
  process = ['Inicio', 'Identidad corporativa', 'Estilos y apariencia', 'Stock', 'Compra', 'Consignación', 'Renovación', 'Financiamiento', 'Contacto', 'Politicas de privacidad', 'Terminos y condiciones', 'Nosotros']
  search = '';
  selected = -1;
  configWebPage: ConfigWebPageClass | null = null;
  systemId = null

  formData = {
    process: '',
  };

  // Methods

  async mounted () {
    const getSystem = (await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: {
        type: {
          name: { _eq: 'system' },
        },
      },
    }))[0]

    const system = await this.fetchData({
      query: { name: 'fetchSpecial', model: 'Person', params: { id: getSystem.id } },
      force: true,
    })
    this.systemId = getSystem.id
    this.configWebPage = system.configWebPage
  }

  openWebSite () {
    const url = VUE_APP_WEB_URL.split('/stock')[0]
    window.open(url, '_blank')
  }

  async refreshConfig () {
    const system = await this.fetchData({
      query: { name: 'fetchSpecial', model: 'Person', params: { id: this.systemId } },
      force: true,
    })

    this.configWebPage = system.configWebPage
    await this.loadNotification({
      // @ts-ignore
      message: `Página web actualizada`,
      notificationType: 'success',
    })
  }

  // Getters
  get filteredProcess () {
    if (!this.search?.length) {
      const lastProcess = this.formData.process
      this.selected = this.process.findIndex(p => p === lastProcess)
      return this.process
    }
    return this.process.filter(p =>
      p.toLowerCase().includes(this.search?.toLowerCase())
    )
  }

  // Watchers
  @Watch('selected', { immediate: true, deep: true })
  onSelectedChange (val) {
    if (val === -1) {
      return
    }

    this.formData.process = this.filteredProcess[val]
    this.$router.replace({
      query: {
        ...this.$route.query, // mantiene los parámetros existentes
        process: this.formData.process,
      },
    }).catch(() => {
    })
  }

  @Watch('$route', { immediate: true, deep: true })
  onRouteChange (val) {
    const { query } = val

    if (query?.process) {
      this.formData.process = query.process
      this.selected = this.process.findIndex(p => p === query.process)
    } else {
      this.selected = 0
      this.formData.process = this.filteredProcess[0]
    }
  }
  }
