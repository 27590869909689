
  import { Component, Watch } from 'vue-property-decorator'
  import PTitle from '@/components/config/PTitle.vue'
  import { ServiceConfigClass } from '@/entities/system/ConfigWebPage'
  import GTextarea from '@/components/core/input/GTextarea.vue'
  import { WebPageView } from '@/components/config/webPage/WebPageView'
  import SeoPreview from '@/components/config/SeoPreview.vue'
  import LinkPreview from '@/components/config/LinkPreview.vue'

@Component({
  components: { LinkPreview, SeoPreview, GTextarea, PTitle },
})
  export default class WebFinancing extends WebPageView {
  formData = {
    seo: {
      title: '',
      subtitle: '',
      alignment: '',
    },
    title: '',
    subtitle: '',
    confirmation: {
      title: '',
      message: '',
      subtitle: '',
    },
  }

  fields = {
    seo: {
      title: 'Ejemplo: Financiamiento - Automotriz',
      description: 'Ejemplo: Consigue el auto de tus sueños con nuestro financiamiento flexible y sin complicaciones.',
    },
    title: 'Ej.: “Obtén tu nuevo vehículo con financiamiento a tu medida”',
    subtitle: 'Ej.: “Completa el formulario y recibe una oferta personalizada para financiar tu auto”',
    confirm: {
      title: 'Ej.: “Tu solicitud de financiamiento ha sido enviada con éxito”',
      subtitle: 'Ej.: “Nuestro equipo revisará tu información y te contactará en breve con más detalles”',
      message: 'Ej.: “Gracias por confiar en nosotros. En breve recibirás información sobre las mejores opciones de financiamiento disponibles para ti”',
    },
  }

  // Methods
  async save () {
    if (!this.$refs.form.validate()) {
      await this.setFieldError()
      return
    }

    const system = await this.fetchData({
      query: { name: 'fetchSpecial', model: 'Person', params: { id: this.systemId } },
      force: true,
    })

    await this.pushData({
      model: 'Person',
      fields: {
        id: this.systemId,
        config_web_page: {
          ...system.configWebPage,
          financing: this.formData,
        },
      },
    })

    this.$emit('update')
  }
  // Getters

  // Watchers
  @Watch('data.financing', { immediate: true, deep: true })
  async onGetData (val: ServiceConfigClass) {
    if (!val) return

    this.formData.title = val.title
    this.formData.subtitle = val.subtitle
    this.formData.confirmation.title = val.confirmation?.title
    this.formData.confirmation.subtitle = val.confirmation?.subtitle
    this.formData.confirmation.message = val.confirmation?.message
    this.formData.seo.title = val?.seo?.title
    this.formData.seo.subtitle = val?.seo?.subtitle
  }
  }
