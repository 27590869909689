
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import PSubtitle from '@/components/config/PSubtitle.vue'

@Component({
  components: { PSubtitle },
})
  export default class SeoPreview extends Vue {
  @Prop({ type: String, default: '' }) favicon!: string;
  @Prop({ type: String, required: true }) title!: string;
  }
