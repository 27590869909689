
  import { Component, Watch } from 'vue-property-decorator'
  import GTextarea from '@/components/core/input/GTextarea.vue'
  import PTitle from '@/components/config/PTitle.vue'
  import { WebPageView } from '@/components/config/webPage/WebPageView'

interface TermsSection {
  id: string;
  title: string;
  description: string;
}

interface TermsAndConditions {
  title: string;
  description: string;
  sections: TermsSection[];
}

@Component({
  components: { PTitle, GTextarea },
})
  export default class WebTerms extends WebPageView {
  valid: boolean = true

  // Estructura del formulario para términos y condiciones.
  terms: TermsAndConditions = {
    title: '',
    description: '',
    sections: [],
  }

  // Regla simple para campos requeridos.
  fieldRequired: ((value: string) => boolean | string)[] = [
    (value: string) => !!value || 'Campo requerido',
  ]

  // Cuando la propiedad data cambia, se inicializa el formulario.
  @Watch('data.termsAndConditions', { immediate: true, deep: true })
  onTermsChanged (val: any) {
    if (val) {
      this.terms.title = val.title || ''
      this.terms.description = val.description || ''
      if (val.content) {
        this.terms.sections = Object.keys(val.content).map(key => ({
          id: key,
          title: val.content[key].title,
          description: val.content[key].description,
        }))
      }
    }
  }

  // Agrega una nueva sección con un id generado dinámicamente.
  addSection () {
    const newId = 'section' + (this.terms.sections.length + 1)
    this.terms.sections.push({
      id: newId,
      title: '',
      description: '',
    })
  }

  // Elimina la sección indicada por su índice.
  removeSection (index: number) {
    this.terms.sections.splice(index, 1)
  }

  // Guarda los datos transformando el arreglo de secciones a objeto.
  async save () {
    if (!this.$refs.form.validate()) {
      return
    }

    const content = this.terms.sections.reduce((acc: any, section) => {
      acc[section.id] = {
        title: section.title,
        description: section.description,
      }
      return acc
    }, {})

    const system = await this.fetchData({
      query: { name: 'fetchSpecial', model: 'Person', params: { id: this.systemId } },
      force: true,
    })

    const termsAndConditions = {
      title: this.terms.title,
      description: this.terms.description,
      content,
    }

    await this.pushData({
      model: 'Person',
      fields: {
        id: this.systemId,
        config_web_page: {
          ...system.configWebPage,
          termsAndConditions,
        },
      },
    })

    this.$emit('update')
  }
  }
