import { Component, Prop } from 'vue-property-decorator'
import { GForm } from '@/components/forms/GForm'
import { ConfigWebPageClass } from '@/entities/system/ConfigWebPage'

@Component
export class WebPageView extends GForm {
  @Prop({ type: [Object, null], default: null, required: true }) data!: ConfigWebPageClass | null
  @Prop({ type: Number, default: null }) systemId!: number

  get faviconUrl () {
    const { data } = this

    return data?.corporateIdentity?.favicon
  }

  get logoUrl () {
    const { data } = this

    return data?.corporateIdentity?.companyLogo
  }

  get backgroundColor () {
    const { data } = this

    return data?.style?.colors?.background
  }
}
