
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import PSubtitle from '@/components/config/PSubtitle.vue'

@Component({
    components: { PSubtitle },
  })
  export default class AlignmentIcon extends Vue {
  @Prop({ type: String, default: 'center' }) value!: string
  selectedAlignment = this.value;

  emitAlignment (position: string) {
    if (this.selectedAlignment !== position) {
      this.selectedAlignment = position
      this.$emit('input', position)
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    }
  }

  elevate (event: Event, hover: boolean) {
    const target = event.currentTarget as HTMLElement
    if (hover) {
      target.setAttribute('elevation', '4')
    } else {
      target.setAttribute('elevation', '1')
    }
  }

  @Watch('value', { immediate: true })
  onValueChange (value: string) {
    this.selectedAlignment = value
  }
  }
