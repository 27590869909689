
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import GTextarea from '@/components/core/input/GTextarea.vue'
  import PTitle from '@/components/config/PTitle.vue'
  import { WebPageView } from '@/components/config/webPage/WebPageView'

interface PrivacySection {
  id: string;
  title: string;
  description: string;
}

interface PrivacyPolicy {
  title: string;
  description: string;
  sections: PrivacySection[];
}

@Component({
  components: { PTitle, GTextarea },
})
  export default class WebPrivacy extends WebPageView {
  valid: boolean = true;

  // Se espera que la data venga con la siguiente estructura:
  // {
  //   privacyPolicy: {
  //     title: "Políticas de Privacidad",
  //     content: {
  //       section1: { title: "...", description: "..." },
  //       section2: { title: "...", description: "..." },
  //       ...
  //     },
  //     description: "..."
  //   }
  // }
  @Prop({ type: Object, default: null })
  readonly data!: any;

  // Datos del formulario convertidos a un formato amigable para la edición.
  policy: PrivacyPolicy = {
    title: '',
    description: '',
    sections: [],
  };

  // Regla simple para campos requeridos.
  fieldRequired: ((value: string) => boolean | string)[] = [
    (value: string) => !!value || 'Campo requerido',
  ];

  // Cuando la propiedad data cambia, se inicializa el formulario.
  @Watch('data.privacyPolicy', { immediate: true, deep: true })
  onPrivacyPolicyChanged (val: any) {
    if (val) {
      this.policy.title = val.title || ''
      this.policy.description = val.description || ''
      // Se convierte el objeto content en un arreglo de secciones.
      if (val.content) {
        this.policy.sections = Object.keys(val.content).map(key => ({
          id: key,
          title: val.content[key].title,
          description: val.content[key].description,
        }))
      }
    }
  }

  // Agrega una nueva sección con un id generado dinámicamente.
  addSection () {
    const newId = 'section' + (this.policy.sections.length + 1)
    this.policy.sections.push({
      id: newId,
      title: '',
      description: '',
    })
  }

  // Elimina la sección indicada por su índice.
  removeSection (index: number) {
    this.policy.sections.splice(index, 1)
  }

  // Guarda los datos transformando las secciones de arreglo a objeto.
  async save () {
    if (!this.$refs.form.validate()) {
      return
    }

    // Transformación: se convierte el arreglo de secciones a un objeto con key/value.
    const content = this.policy.sections.reduce((acc: any, section) => {
      acc[section.id] = {
        title: section.title,
        description: section.description,
      }
      return acc
    }, {})

    const system = await this.fetchData({
      query: { name: 'fetchSpecial', model: 'Person', params: { id: this.systemId } },
      force: true,
    })

    const privacyPolicy = {
      title: this.policy.title,
      description: this.policy.description,
      content,
    }

    await this.pushData({
      model: 'Person',
      fields: {
        id: this.systemId,
        config_web_page: {
          ...system.configWebPage,
          privacyPolicy,
        },
      },
    })

    // Emite el evento update para notificar al padre.
    this.$emit('update')
  }
  }
