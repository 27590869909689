
  import { Component, Watch } from 'vue-property-decorator'
  import PTitle from '@/components/config/PTitle.vue'
  import { ServiceConfigClass } from '@/entities/system/ConfigWebPage'
  import GTextarea from '@/components/core/input/GTextarea.vue'
  import { WebPageView } from '@/components/config/webPage/WebPageView'
  import SeoPreview from '@/components/config/SeoPreview.vue'
  import LinkPreview from '@/components/config/LinkPreview.vue'

@Component({
  components: { LinkPreview, SeoPreview, GTextarea, PTitle },
})
  export default class WebContact extends WebPageView {
  formData = {
    seo: {
      title: '',
      subtitle: '',
      alignment: '',
    },
    title: '',
    subtitle: '',
    confirmation: {
      title: '',
      message: '',
      subtitle: '',
    },
  }

  fields = {
    seo: {
      title: 'Ejemplo: Venta - Automotriz',
      description: 'Ejemplo: Obtén la mejor oferta por tu auto en minutos. Proceso rápido, seguro y sin complicaciones.',
    },
    title: 'Ej.: “Vende tu auto de manera rápida y segura”',
    subtitle: 'Ej.: “Completa el formulario y recibe una oferta competitiva en poco tiempo”',
    confirm: {
      title: 'Ej.: “¡Tu solicitud ha sido enviada con éxito!”',
      subtitle: 'Ej.: “Nuestro equipo analizará la información y se pondrá en contacto contigo a la brevedad”',
      message: 'Ej.: “Gracias por confiar en nosotros. En breve recibirás nuestra mejor oferta por tu vehículo”',
    },
  }

  // Methods
  async save () {
    if (!this.$refs.form.validate()) {
      await this.setFieldError()
      return
    }

    const system = await this.fetchData({
      query: { name: 'fetchSpecial', model: 'Person', params: { id: this.systemId } },
      force: true,
    })

    await this.pushData({
      model: 'Person',
      fields: {
        id: this.systemId,
        config_web_page: {
          ...system.configWebPage,
          contact: this.formData,
        },
      },
    })

    this.$emit('update')
  }
  // Getters

  // Watchers
  @Watch('data.contact', { immediate: true, deep: true })
  async onGetData (val: ServiceConfigClass) {
    if (!val) return

    this.formData.title = val.title
    this.formData.subtitle = val.subtitle
    this.formData.confirmation.title = val.confirmation?.title
    this.formData.confirmation.subtitle = val.confirmation?.subtitle
    this.formData.confirmation.message = val.confirmation?.message
    this.formData.seo.title = val?.seo?.title
    this.formData.seo.subtitle = val?.seo?.subtitle
  }
  }
